import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`22nd December 2022`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://@RequestTimeout:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/RequestTimeout.html"
          }}>{`type://@RequestTimeout`}</a>{` to set a request timeout to annotated services and
gRPC services. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4463"
          }}>{`#4463`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4499"
          }}>{`#4499`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.server.annotation.decorator.RequestTimeout;

@RequestTimeout(value = 1, unit = TimeUnit.SECONDS) // 👈👈👈
class MyAnnotationService {
  // A request gets timed out after 1 second.
  @Get("/fast/api")
  public CompletableFuture<MyResponse> fastApi() {
    ...
  }

  // A request gets timed out after 5 seconds.
  @RequestTimeout(value = 5, unit = TimeUnit.SECONDS) // 👈👈👈
  public CompletableFuture<MyResponse> slowApi() {
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set the default `}<inlineCode parentName="p">{`MeterRegistry`}</inlineCode>{` by implementing a custom `}<a parentName="p" {...{
            "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
          }}>{`type://FlagsProvider`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4541"
          }}>{`#4541`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`public class CustomFlagsProvider implements FlagsProvider {
  @Override
  public MeterRegistry meterRegistry() {
    return PrometheusMeterRegistries.newRegistry(); // 👈👈👈
  }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set the default `}<a parentName="p" {...{
            "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
          }}>{`type://HttpHeaders`}</a>{` to `}<a parentName="p" {...{
            "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
          }}>{`type://Server`}</a>{`, `}<a parentName="p" {...{
            "href": "type://VirtualHost:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHost.html"
          }}>{`type://VirtualHost`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://Service:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Service.html"
          }}>{`type://Service`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4520"
          }}>{`#4520`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`static AsciiString CUSTOM_HEADER =
  HttpHeaderNames.of("x-custom-header");

Server
  .builder()
  .setHeader(CUSTOM_HEADER, "server-default-header")
  .virtualHost("foo.com")
  .setHeader(CUSTOM_HEADER, "vhost-default-header").and()
  .route()
  .path("/foo")
  .setHeader(CUSTOM_HEADER, "service-default-header")
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now set `}<inlineCode parentName="p">{`:authority`}</inlineCode>{` or `}<inlineCode parentName="p">{`Host`}</inlineCode>{` header whose value is different from `}<a parentName="p" {...{
            "href": "type://Endpoint#host():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Endpoint.html#host()"
          }}>{`type://Endpoint#host()`}</a>{` using
`}<a parentName="p" {...{
            "href": "type://ClientBuilder#setHeader(CharSequence,Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html#setHeader(java.lang.CharSequence,java.lang.Object)"
          }}>{`type://ClientBuilder#setHeader(CharSequence,Object)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4440"
          }}>{`#4440`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4520"
          }}>{`#4520`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`This will be useful when you want to send a request through a proxy server.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebClient
  // A request is sent to 'reverse-proxy.example.com'.
  .builder("http://reverse-proxy.example.com/")
  // but 'my-order.service.com' is used for the ':authority' header.
  .setHeader(HeaderNames.AUTHORITY, "my-order.service.com")
  ...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
          }}>{`type://CircuitBreakerClient`}</a>{` can now be used with arbitrary `}<a parentName="p" {...{
            "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
          }}>{`type://CircuitBreaker`}</a>{` implementations. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4426"
          }}>{`#4426`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added `}<a parentName="p" {...{
            "href": "type://Bytes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Bytes.html"
          }}>{`type://Bytes`}</a>{` that represents binary data. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4505"
          }}>{`#4505`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now see the description of services in the overview section of `}<a parentName="p" {...{
            "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
          }}>{`type://DocService`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4480"
          }}>{`#4480`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4537"
          }}>{`#4537`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now override an authority of a gRPC client using `}<inlineCode parentName="p">{`CallOptions`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4445"
          }}>{`#4445`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4520"
          }}>{`#4520`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DecodingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/encoding/DecodingClient.html"
        }}>{`type://DecodingClient`}</a>{` and `}<a parentName="li" {...{
          "href": "type://DecodingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/DecodingService.html"
        }}>{`type://DecodingService`}</a>{` can now limit the maximum length of decompressed
data. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4469"
        }}>{`#4469`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4564"
        }}>{`#4564`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`synchronized`}</inlineCode>{` blocks have been replaced with `}<a parentName="li" {...{
          "href": "type://ReentrantLock"
        }}>{`type://ReentrantLock`}</a>{` for better virtual thread
compatibility. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4551"
        }}>{`#4551`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4549"
        }}>{`#4549`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceSpecification:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/ServiceSpecification.html"
        }}>{`type://ServiceSpecification`}</a>{` for `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` is lazily loaded so as to not block a `}<a parentName="li" {...{
          "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
        }}>{`type://Server`}</a>{`
from starting. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4491"
        }}>{`#4491`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
        }}>{`type://HttpHeaders`}</a>{` set by `}<a parentName="li" {...{
          "href": "type://ClientBuilder#setHeader(CharSequence,Object):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html#setHeader(java.lang.CharSequence,java.lang.Object)"
        }}>{`type://ClientBuilder#setHeader(CharSequence,Object)`}</a>{` is now stored in
`}<a parentName="li" {...{
          "href": "type://ClientRequestContext#defaultRequestHeaders():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#defaultRequestHeaders()"
        }}>{`type://ClientRequestContext#defaultRequestHeaders()`}</a>{` which has the lowest priority and doesn't override
values in `}<a parentName="li" {...{
          "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
        }}>{`type://RequestHeaders`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Previously, the `}<a parentName="li" {...{
              "href": "type://HttpHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpHeaders.html"
            }}>{`type://HttpHeaders`}</a>{` set via `}<a parentName="li" {...{
              "href": "type://ClientBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientBuilder.html"
            }}>{`type://ClientBuilder`}</a>{` were stored in
`}<a parentName="li" {...{
              "href": "type://ClientRequestContext#additionalRequestHeaders():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContext.html#additionalRequestHeaders()"
            }}>{`type://ClientRequestContext#additionalRequestHeaders()`}</a>{` which overrode the values in
`}<a parentName="li" {...{
              "href": "type://RequestHeaders:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestHeaders.html"
            }}>{`type://RequestHeaders`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/4440"
            }}>{`#4440`}</a>{` `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/4520"
            }}>{`#4520`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Fixed a regression where `}<inlineCode parentName="li">{`void`}</inlineCode>{` methods return `}<inlineCode parentName="li">{`200 OK`}</inlineCode>{` instead of `}<inlineCode parentName="li">{`204 NoContent`}</inlineCode>{` if a `}<a parentName="li" {...{
          "href": "type://@Produces:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/Produces.html"
        }}>{`type://@Produces`}</a>{`
annotation is added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4575"
        }}>{`#4575`}</a></li>
      <li parentName="ul">{`Deleted uncompressed `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` assets that were added unintentionally. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4500"
        }}>{`#4500`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4568"
        }}>{`#4568`}</a></li>
      <li parentName="ul">{`An exception raised in `}<a parentName="li" {...{
          "href": "type://Authorizer:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/auth/Authorizer.html"
        }}>{`type://Authorizer`}</a>{` is now properly peeled using
`}<a parentName="li" {...{
          "href": "type://Exceptions#peel(Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/Exceptions.html#peel(java.lang.Throwable)"
        }}>{`type://Exceptions#peel(Throwable)`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4567"
        }}>{`#4567`}</a></li>
      <li parentName="ul">{`Exceptions raised in decorators can now be used to determine whether to retry by `}<a parentName="li" {...{
          "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
        }}>{`type://RetryingClient`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4558"
        }}>{`#4558`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4559"
        }}>{`#4559`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/EndpointGroup.html"
        }}>{`type://EndpointGroup`}</a>{` is now correctly copied to a newly derived client. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4556"
        }}>{`#4556`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestContextExportingAppender:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logback/RequestContextExportingAppender.html"
        }}>{`type://RequestContextExportingAppender`}</a>{` can now be used with logback `}<inlineCode parentName="li">{`SocketAppender`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4546"
        }}>{`#4546`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4547"
        }}>{`#4547`}</a></li>
      <li parentName="ul">{`Reversed HTTP/1.1 responses are now correctly ordered when HTTP/1.1 pipelining is enabled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4536"
        }}>{`#4536`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4470"
        }}>{`#4470`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`414 Request-URI Too Long`}</inlineCode>{` is now correctly returned for a long URI in HTTP/1.1. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4478"
        }}>{`#4478`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4532"
        }}>{`#4532`}</a></li>
      <li parentName="ul">{`Fixed to propagate the cause of a decoder failure of an HTTP/1.1 request to
`}<a parentName="li" {...{
          "href": "type://ServerErrorHandler#onProtocolViolation(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerErrorHandler.html#onProtocolViolation(com.linecorp.armeria.server.ServiceConfig,com.linecorp.armeria.common.RequestHeaders,com.linecorp.armeria.common.HttpStatus,java.lang.String,java.lang.Throwable)"
        }}>{`type://ServerErrorHandler#onProtocolViolation(ServiceConfig,RequestHeaders,HttpStatus,String,Throwable)`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4478"
        }}>{`#4478`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4532"
        }}>{`#4532`}</a></li>
      <li parentName="ul">{`You can use `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` even when `}<a parentName="li" {...{
          "href": "type://Server#reconfigure(ServerConfigurator):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html#reconfigure(com.linecorp.armeria.server.ServerConfigurator)"
        }}>{`type://Server#reconfigure(ServerConfigurator)`}</a>{` is called. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4491"
        }}>{`#4491`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4513"
        }}>{`#4513`}</a></li>
      <li parentName="ul">{`Context leak stack trace is more readable now. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4517"
        }}>{`#4517`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`N/A`}</li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`FieldInfo.childFieldInfo`}</inlineCode>{` has been removed to eliminate duplicate information on `}<a parentName="li" {...{
          "href": "type://StructInfo:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/StructInfo.html"
        }}>{`type://StructInfo`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4566"
        }}>{`#4566`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Apache Curator 5.3.0 → 5.4.0`}</li>
      <li parentName="ul">{`Brave 5.14.0 → 5.14.1`}</li>
      <li parentName="ul">{`Dropwizard 2.1.1 → 2.1.4`}</li>
      <li parentName="ul">{`Eureka 1.10.17 → 2.0.0`}</li>
      <li parentName="ul">{`GraphQL Java 19.2 → 20.0`}</li>
      <li parentName="ul">{`Jackson 2.13.4 → 2.14.1`}</li>
      <li parentName="ul">{`java-jwt 4.0.0 → 4.2.1`}</li>
      <li parentName="ul">{`Jetty 9.4.48 → 9.4.50`}</li>
      <li parentName="ul">{`Kafka client 3.3.0 → 3.3.1`}</li>
      <li parentName="ul">{`Kotlin 1.7.20 → 1.7.22`}</li>
      <li parentName="ul">{`Micrometer 1.9.4 → 1.10.2`}</li>
      <li parentName="ul">{`Netty 4.1.82 → 4.1.86`}
        <ul parentName="li">
          <li parentName="ul">{`io_uring 0.0.15 → 0.0.16`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Reactor 3.4.23 → 3.4.26`}</li>
      <li parentName="ul">{`Sangria 3.3.0 → 3.4.1`}</li>
      <li parentName="ul">{`Sangria slowlog 2.0.4 → 2.0.5`}</li>
      <li parentName="ul">{`Scala 2.13.9 → 2.13.10`}</li>
      <li parentName="ul">{`scala-collection-compat 2.8.1 → 2.9.0`}</li>
      <li parentName="ul">{`ScalaPB 0.11.11 → 0.11.12`}</li>
      <li parentName="ul">{`Tomcat 8.5.81 → 8.5.84, 9.0.65 → 9.0.70`}</li>
      <li parentName="ul">{`ZooKeeper 3.6.3 → 3.7.1`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Bue-von-hon', 'Dogacel', 'Jimexist', 'Lincong', 'Yuri999', 'chris-ryan-square', 'ikhoon', 'jrhee17', 'ks-yim', 'minwoox', 'mscheong01', 'tobias-', 'Trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      